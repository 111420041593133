import { default as editKljky7ABQ4Meta } from "/codebuild/output/src1361907415/src/pages/admin/account_plans/[account_plan_id]/edit.vue?macro=true";
import { default as indexuEuMZBps9DMeta } from "/codebuild/output/src1361907415/src/pages/admin/account_plans/[account_plan_id]/index.vue?macro=true";
import { default as _91account_plan_id_93h44NZii8peMeta } from "/codebuild/output/src1361907415/src/pages/admin/account_plans/[account_plan_id].vue?macro=true";
import { default as editvbbOYd42UOMeta } from "/codebuild/output/src1361907415/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indexNrbT0PO0WKMeta } from "/codebuild/output/src1361907415/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93gylL7fPQqAMeta } from "/codebuild/output/src1361907415/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexolELAnk2peMeta } from "/codebuild/output/src1361907415/src/pages/admin/account_requested_plans/index.vue?macro=true";
import { default as newrcdyGswokRMeta } from "/codebuild/output/src1361907415/src/pages/admin/account_requested_plans/new.vue?macro=true";
import { default as account_requested_planskLe1XFMAKwMeta } from "/codebuild/output/src1361907415/src/pages/admin/account_requested_plans.vue?macro=true";
import { default as edite8S0GiOVWRMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indexfByCaq2oV9Meta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93drJLkVG3tXMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexAUsLWydVcXMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue?macro=true";
import { default as newg8Pph9S3rOMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue?macro=true";
import { default as account_requested_planso6imziCIYLMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/account_requested_plans.vue?macro=true";
import { default as editS16xzKHQjCMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexF8bcX28C5jMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_932CMn5JCtT8Meta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexKOMdHDFtP7Meta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/caller_ids/index.vue?macro=true";
import { default as newEuutoLSh6KMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/caller_ids/new.vue?macro=true";
import { default as caller_ids6XyMC4XGuVMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/caller_ids.vue?macro=true";
import { default as edith9sPfcxgowMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/edit.vue?macro=true";
import { default as indexKmmqTO0C1aMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/index.vue?macro=true";
import { default as editSv8YGbihAvMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexzCjr72B2qiMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93lAow7WECs6Meta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexm0szVIlVpFMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue?macro=true";
import { default as newOUPuwvVrUjMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue?macro=true";
import { default as sms_maskingsePHaxjtKN4Meta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/sms_maskings.vue?macro=true";
import { default as _91account_id_93iTdeq8kmsPMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id].vue?macro=true";
import { default as index0fkm3CKHVpMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/index.vue?macro=true";
import { default as wizard_plan_selectionFDMXPZ6iSGMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/wizard_plan_selection.vue?macro=true";
import { default as wizardW6EBaa6726Meta } from "/codebuild/output/src1361907415/src/pages/admin/accounts/wizard.vue?macro=true";
import { default as accountsmMCcpdELLFMeta } from "/codebuild/output/src1361907415/src/pages/admin/accounts.vue?macro=true";
import { default as editTrilwXlIldMeta } from "/codebuild/output/src1361907415/src/pages/admin/admin_users/[admin_user_id]/edit.vue?macro=true";
import { default as indexzHP38TsHzDMeta } from "/codebuild/output/src1361907415/src/pages/admin/admin_users/[admin_user_id]/index.vue?macro=true";
import { default as _91admin_user_id_93pnaUawiWl2Meta } from "/codebuild/output/src1361907415/src/pages/admin/admin_users/[admin_user_id].vue?macro=true";
import { default as indexTyPz8l4HFOMeta } from "/codebuild/output/src1361907415/src/pages/admin/admin_users/index.vue?macro=true";
import { default as new3GBkW2AqpHMeta } from "/codebuild/output/src1361907415/src/pages/admin/admin_users/new.vue?macro=true";
import { default as admin_userstt92EZpw6KMeta } from "/codebuild/output/src1361907415/src/pages/admin/admin_users.vue?macro=true";
import { default as indexijQZXXGCokMeta } from "/codebuild/output/src1361907415/src/pages/admin/audit_trails/index.vue?macro=true";
import { default as audit_trailsCFpMszjttOMeta } from "/codebuild/output/src1361907415/src/pages/admin/audit_trails.vue?macro=true";
import { default as edit01Z8EUi4heMeta } from "/codebuild/output/src1361907415/src/pages/admin/billings/[billing_id]/edit.vue?macro=true";
import { default as indexAYreFot7IaMeta } from "/codebuild/output/src1361907415/src/pages/admin/billings/[billing_id]/index.vue?macro=true";
import { default as _91billing_id_93TPQYjO4hv5Meta } from "/codebuild/output/src1361907415/src/pages/admin/billings/[billing_id].vue?macro=true";
import { default as index64aKbdVnWfMeta } from "/codebuild/output/src1361907415/src/pages/admin/billings/index.vue?macro=true";
import { default as newxWRczke4mLMeta } from "/codebuild/output/src1361907415/src/pages/admin/billings/new.vue?macro=true";
import { default as billingsnZjAfmM56AMeta } from "/codebuild/output/src1361907415/src/pages/admin/billings.vue?macro=true";
import { default as edithTHbpT76MOMeta } from "/codebuild/output/src1361907415/src/pages/admin/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexla5qcqV0hpMeta } from "/codebuild/output/src1361907415/src/pages/admin/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_93XlmYGCstB5Meta } from "/codebuild/output/src1361907415/src/pages/admin/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexOdpCKnDdzNMeta } from "/codebuild/output/src1361907415/src/pages/admin/caller_ids/index.vue?macro=true";
import { default as newBrpTKmnZVmMeta } from "/codebuild/output/src1361907415/src/pages/admin/caller_ids/new.vue?macro=true";
import { default as caller_ids6TrLWNXncMMeta } from "/codebuild/output/src1361907415/src/pages/admin/caller_ids.vue?macro=true";
import { default as edityOKqgJd5JKMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue?macro=true";
import { default as indexG6brhHhAlOMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue?macro=true";
import { default as _91campaign_setting_id_93IVL1Am38MfMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaign_settings/[campaign_setting_id].vue?macro=true";
import { default as editZrwtEdd4GDMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/edit.vue?macro=true";
import { default as index70K6Qbr52hMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/index.vue?macro=true";
import { default as editb2hq4pgZizMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue?macro=true";
import { default as indexEVbMofXobDMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue?macro=true";
import { default as _91outbound_call_transaction_id_93rhHWj9IzL2Meta } from "/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue?macro=true";
import { default as indexBe11ksMGcvMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue?macro=true";
import { default as newSzb32VpQlNMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue?macro=true";
import { default as outbound_call_transactions9zt2s2P8ONMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue?macro=true";
import { default as _91campaign_id_93h0TbmorRyBMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id].vue?macro=true";
import { default as indexRma0NuSLtXMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaigns/index.vue?macro=true";
import { default as newiK8DwTyuAVMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaigns/new.vue?macro=true";
import { default as campaignsVRUcuUV64sMeta } from "/codebuild/output/src1361907415/src/pages/admin/campaigns.vue?macro=true";
import { default as indexkVkxjwxWM7Meta } from "/codebuild/output/src1361907415/src/pages/admin/custom_emails/[custom_email_id]/index.vue?macro=true";
import { default as _91custom_email_id_93oJwyUItSMDMeta } from "/codebuild/output/src1361907415/src/pages/admin/custom_emails/[custom_email_id].vue?macro=true";
import { default as indexStDvJBuxAGMeta } from "/codebuild/output/src1361907415/src/pages/admin/custom_emails/index.vue?macro=true";
import { default as newHfolatDFNlMeta } from "/codebuild/output/src1361907415/src/pages/admin/custom_emails/new.vue?macro=true";
import { default as custom_emailsRRjPs1uuBrMeta } from "/codebuild/output/src1361907415/src/pages/admin/custom_emails.vue?macro=true";
import { default as indexuwEC4w4dV5Meta } from "/codebuild/output/src1361907415/src/pages/admin/dashboard/index.vue?macro=true";
import { default as dashboard4NSdhzYpsmMeta } from "/codebuild/output/src1361907415/src/pages/admin/dashboard.vue?macro=true";
import { default as indexliWPATasyaMeta } from "/codebuild/output/src1361907415/src/pages/admin/inquiries/[inquiry_id]/index.vue?macro=true";
import { default as _91inquiry_id_93JBM0zRglleMeta } from "/codebuild/output/src1361907415/src/pages/admin/inquiries/[inquiry_id].vue?macro=true";
import { default as indexGSgp1bmeS8Meta } from "/codebuild/output/src1361907415/src/pages/admin/inquiries/index.vue?macro=true";
import { default as new24ct2pB0STMeta } from "/codebuild/output/src1361907415/src/pages/admin/inquiries/new.vue?macro=true";
import { default as inquiriesid3DABxRlgMeta } from "/codebuild/output/src1361907415/src/pages/admin/inquiries.vue?macro=true";
import { default as indexZF5yMykjT9Meta } from "/codebuild/output/src1361907415/src/pages/admin/login/index.vue?macro=true";
import { default as loginHMXcqaDb8zMeta } from "/codebuild/output/src1361907415/src/pages/admin/login.vue?macro=true";
import { default as newL3xvsrItCrMeta } from "/codebuild/output/src1361907415/src/pages/admin/password/new.vue?macro=true";
import { default as indexj9lBr8ZZTbMeta } from "/codebuild/output/src1361907415/src/pages/admin/reports/index.vue?macro=true";
import { default as reportsjgFba8EPFnMeta } from "/codebuild/output/src1361907415/src/pages/admin/reports.vue?macro=true";
import { default as edit3SZGpc10zXMeta } from "/codebuild/output/src1361907415/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexIORCTKtGQyMeta } from "/codebuild/output/src1361907415/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93Zo0uMMdvqZMeta } from "/codebuild/output/src1361907415/src/pages/admin/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as index5eMn98zc43Meta } from "/codebuild/output/src1361907415/src/pages/admin/sms_maskings/index.vue?macro=true";
import { default as newacdkRpPL9CMeta } from "/codebuild/output/src1361907415/src/pages/admin/sms_maskings/new.vue?macro=true";
import { default as sms_maskingsRiIoQCh5ROMeta } from "/codebuild/output/src1361907415/src/pages/admin/sms_maskings.vue?macro=true";
import { default as editvQvAprB685Meta } from "/codebuild/output/src1361907415/src/pages/admin/users/[user_id]/edit.vue?macro=true";
import { default as indexn4lhh0NuiZMeta } from "/codebuild/output/src1361907415/src/pages/admin/users/[user_id]/index.vue?macro=true";
import { default as _91user_id_93ZelpQh0JTaMeta } from "/codebuild/output/src1361907415/src/pages/admin/users/[user_id].vue?macro=true";
import { default as indexnNGVmkZOeBMeta } from "/codebuild/output/src1361907415/src/pages/admin/users/index.vue?macro=true";
import { default as newp0iFl67aimMeta } from "/codebuild/output/src1361907415/src/pages/admin/users/new.vue?macro=true";
import { default as users6KdAXy1AoHMeta } from "/codebuild/output/src1361907415/src/pages/admin/users.vue?macro=true";
import { default as editnnZgKaBih2Meta } from "/codebuild/output/src1361907415/src/pages/admin/wallets/[wallet_id]/edit.vue?macro=true";
import { default as indexBrFmW6S3mKMeta } from "/codebuild/output/src1361907415/src/pages/admin/wallets/[wallet_id]/index.vue?macro=true";
import { default as _91wallet_id_93wLK2mxdZSTMeta } from "/codebuild/output/src1361907415/src/pages/admin/wallets/[wallet_id].vue?macro=true";
import { default as adminfP47rE7RqOMeta } from "/codebuild/output/src1361907415/src/pages/admin.vue?macro=true";
import { default as index6s1evIXF7sMeta } from "/codebuild/output/src1361907415/src/pages/audit_logs/index.vue?macro=true";
import { default as indexto88jtIEgzMeta } from "/codebuild/output/src1361907415/src/pages/billings/index.vue?macro=true";
import { default as indexjlXl3Deee0Meta } from "/codebuild/output/src1361907415/src/pages/call_out/reports/index.vue?macro=true";
import { default as _91id_93lCt3XpAd1IMeta } from "/codebuild/output/src1361907415/src/pages/call_recordings/[id].vue?macro=true";
import { default as indexhUuatcgtUHMeta } from "/codebuild/output/src1361907415/src/pages/campaigns/[id]/index.vue?macro=true";
import { default as indexAt8Gb4tLJrMeta } from "/codebuild/output/src1361907415/src/pages/campaigns/index.vue?macro=true";
import { default as indexbNtYr5ZZQCMeta } from "/codebuild/output/src1361907415/src/pages/dashboard/index.vue?macro=true";
import { default as indexo5R5uE4KJJMeta } from "/codebuild/output/src1361907415/src/pages/help/index.vue?macro=true";
import { default as indexmiTWJIGrUtMeta } from "/codebuild/output/src1361907415/src/pages/index.vue?macro=true";
import { default as index8FayRMQPRZMeta } from "/codebuild/output/src1361907415/src/pages/inquiry/index.vue?macro=true";
import { default as editorKVpcp4OQzzMeta } from "/codebuild/output/src1361907415/src/pages/ivr_trees/[id]/editor.vue?macro=true";
import { default as indexyGb91IC3xMMeta } from "/codebuild/output/src1361907415/src/pages/ivr_trees/index.vue?macro=true";
import { default as verify_emailcQ9ooqLtffMeta } from "/codebuild/output/src1361907415/src/pages/profiles/[user_id]/verify_email.vue?macro=true";
import { default as welcome_dashboardtKyfUfRYeaMeta } from "/codebuild/output/src1361907415/src/pages/profiles/[user_id]/welcome_dashboard.vue?macro=true";
import { default as indexQtcYFUcAPGMeta } from "/codebuild/output/src1361907415/src/pages/profiles/index.vue?macro=true";
import { default as indexiW3qyS6xj1Meta } from "/codebuild/output/src1361907415/src/pages/sub_users/users/index.vue?macro=true";
import { default as indexCsEmIWPQOXMeta } from "/codebuild/output/src1361907415/src/pages/terms_and_conditions/index.vue?macro=true";
import { default as indexQur1sWVi9LMeta } from "/codebuild/output/src1361907415/src/pages/users/password/edit/index.vue?macro=true";
import { default as index9SJkdbIvl9Meta } from "/codebuild/output/src1361907415/src/pages/users/password/new/index.vue?macro=true";
import { default as indexC7Lb2l9U4hMeta } from "/codebuild/output/src1361907415/src/pages/users/sign_in/index.vue?macro=true";
export default [
  {
    name: adminfP47rE7RqOMeta?.name ?? "admin",
    path: adminfP47rE7RqOMeta?.path ?? "/admin",
    children: [
  {
    path: _91account_plan_id_93h44NZii8peMeta?.path ?? "account_plans/:account_plan_id()",
    children: [
  {
    name: editKljky7ABQ4Meta?.name ?? "admin-account_plans-account_plan_id-edit",
    path: editKljky7ABQ4Meta?.path ?? "edit",
    meta: editKljky7ABQ4Meta || {},
    alias: editKljky7ABQ4Meta?.alias || [],
    redirect: editKljky7ABQ4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/account_plans/[account_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexuEuMZBps9DMeta?.name ?? "admin-account_plans-account_plan_id",
    path: indexuEuMZBps9DMeta?.path ?? "",
    meta: indexuEuMZBps9DMeta || {},
    alias: indexuEuMZBps9DMeta?.alias || [],
    redirect: indexuEuMZBps9DMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/account_plans/[account_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_plan_id_93h44NZii8peMeta?.name ?? undefined,
    meta: _91account_plan_id_93h44NZii8peMeta || {},
    alias: _91account_plan_id_93h44NZii8peMeta?.alias || [],
    redirect: _91account_plan_id_93h44NZii8peMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/account_plans/[account_plan_id].vue").then(m => m.default || m)
  },
  {
    path: account_requested_planskLe1XFMAKwMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93gylL7fPQqAMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editvbbOYd42UOMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id-edit",
    path: editvbbOYd42UOMeta?.path ?? "edit",
    meta: editvbbOYd42UOMeta || {},
    alias: editvbbOYd42UOMeta?.alias || [],
    redirect: editvbbOYd42UOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexNrbT0PO0WKMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id",
    path: indexNrbT0PO0WKMeta?.path ?? "",
    meta: indexNrbT0PO0WKMeta || {},
    alias: indexNrbT0PO0WKMeta?.alias || [],
    redirect: indexNrbT0PO0WKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93gylL7fPQqAMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93gylL7fPQqAMeta || {},
    alias: _91account_requested_plan_id_93gylL7fPQqAMeta?.alias || [],
    redirect: _91account_requested_plan_id_93gylL7fPQqAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexolELAnk2peMeta?.name ?? "admin-account_requested_plans",
    path: indexolELAnk2peMeta?.path ?? "",
    meta: indexolELAnk2peMeta || {},
    alias: indexolELAnk2peMeta?.alias || [],
    redirect: indexolELAnk2peMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newrcdyGswokRMeta?.name ?? "admin-account_requested_plans-new",
    path: newrcdyGswokRMeta?.path ?? "new",
    meta: newrcdyGswokRMeta || {},
    alias: newrcdyGswokRMeta?.alias || [],
    redirect: newrcdyGswokRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_planskLe1XFMAKwMeta?.name ?? undefined,
    meta: account_requested_planskLe1XFMAKwMeta || {},
    alias: account_requested_planskLe1XFMAKwMeta?.alias || [],
    redirect: account_requested_planskLe1XFMAKwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: accountsmMCcpdELLFMeta?.path ?? "accounts",
    children: [
  {
    path: _91account_id_93iTdeq8kmsPMeta?.path ?? ":account_id()",
    children: [
  {
    path: account_requested_planso6imziCIYLMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93drJLkVG3tXMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: edite8S0GiOVWRMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id-edit",
    path: edite8S0GiOVWRMeta?.path ?? "edit",
    meta: edite8S0GiOVWRMeta || {},
    alias: edite8S0GiOVWRMeta?.alias || [],
    redirect: edite8S0GiOVWRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexfByCaq2oV9Meta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id",
    path: indexfByCaq2oV9Meta?.path ?? "",
    meta: indexfByCaq2oV9Meta || {},
    alias: indexfByCaq2oV9Meta?.alias || [],
    redirect: indexfByCaq2oV9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93drJLkVG3tXMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93drJLkVG3tXMeta || {},
    alias: _91account_requested_plan_id_93drJLkVG3tXMeta?.alias || [],
    redirect: _91account_requested_plan_id_93drJLkVG3tXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexAUsLWydVcXMeta?.name ?? "admin-accounts-account_id-account_requested_plans",
    path: indexAUsLWydVcXMeta?.path ?? "",
    meta: indexAUsLWydVcXMeta || {},
    alias: indexAUsLWydVcXMeta?.alias || [],
    redirect: indexAUsLWydVcXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newg8Pph9S3rOMeta?.name ?? "admin-accounts-account_id-account_requested_plans-new",
    path: newg8Pph9S3rOMeta?.path ?? "new",
    meta: newg8Pph9S3rOMeta || {},
    alias: newg8Pph9S3rOMeta?.alias || [],
    redirect: newg8Pph9S3rOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_planso6imziCIYLMeta?.name ?? undefined,
    meta: account_requested_planso6imziCIYLMeta || {},
    alias: account_requested_planso6imziCIYLMeta?.alias || [],
    redirect: account_requested_planso6imziCIYLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: caller_ids6XyMC4XGuVMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_932CMn5JCtT8Meta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editS16xzKHQjCMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id-edit",
    path: editS16xzKHQjCMeta?.path ?? "edit",
    meta: editS16xzKHQjCMeta || {},
    alias: editS16xzKHQjCMeta?.alias || [],
    redirect: editS16xzKHQjCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexF8bcX28C5jMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id",
    path: indexF8bcX28C5jMeta?.path ?? "",
    meta: indexF8bcX28C5jMeta || {},
    alias: indexF8bcX28C5jMeta?.alias || [],
    redirect: indexF8bcX28C5jMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_932CMn5JCtT8Meta?.name ?? undefined,
    meta: _91caller_id_id_932CMn5JCtT8Meta || {},
    alias: _91caller_id_id_932CMn5JCtT8Meta?.alias || [],
    redirect: _91caller_id_id_932CMn5JCtT8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexKOMdHDFtP7Meta?.name ?? "admin-accounts-account_id-caller_ids",
    path: indexKOMdHDFtP7Meta?.path ?? "",
    meta: indexKOMdHDFtP7Meta || {},
    alias: indexKOMdHDFtP7Meta?.alias || [],
    redirect: indexKOMdHDFtP7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newEuutoLSh6KMeta?.name ?? "admin-accounts-account_id-caller_ids-new",
    path: newEuutoLSh6KMeta?.path ?? "new",
    meta: newEuutoLSh6KMeta || {},
    alias: newEuutoLSh6KMeta?.alias || [],
    redirect: newEuutoLSh6KMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_ids6XyMC4XGuVMeta?.name ?? undefined,
    meta: caller_ids6XyMC4XGuVMeta || {},
    alias: caller_ids6XyMC4XGuVMeta?.alias || [],
    redirect: caller_ids6XyMC4XGuVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/caller_ids.vue").then(m => m.default || m)
  },
  {
    name: edith9sPfcxgowMeta?.name ?? "admin-accounts-account_id-edit",
    path: edith9sPfcxgowMeta?.path ?? "edit",
    meta: edith9sPfcxgowMeta || {},
    alias: edith9sPfcxgowMeta?.alias || [],
    redirect: edith9sPfcxgowMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexKmmqTO0C1aMeta?.name ?? "admin-accounts-account_id",
    path: indexKmmqTO0C1aMeta?.path ?? "",
    meta: indexKmmqTO0C1aMeta || {},
    alias: indexKmmqTO0C1aMeta?.alias || [],
    redirect: indexKmmqTO0C1aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/index.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingsePHaxjtKN4Meta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93lAow7WECs6Meta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: editSv8YGbihAvMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id-edit",
    path: editSv8YGbihAvMeta?.path ?? "edit",
    meta: editSv8YGbihAvMeta || {},
    alias: editSv8YGbihAvMeta?.alias || [],
    redirect: editSv8YGbihAvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexzCjr72B2qiMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id",
    path: indexzCjr72B2qiMeta?.path ?? "",
    meta: indexzCjr72B2qiMeta || {},
    alias: indexzCjr72B2qiMeta?.alias || [],
    redirect: indexzCjr72B2qiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93lAow7WECs6Meta?.name ?? undefined,
    meta: _91sms_masking_id_93lAow7WECs6Meta || {},
    alias: _91sms_masking_id_93lAow7WECs6Meta?.alias || [],
    redirect: _91sms_masking_id_93lAow7WECs6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexm0szVIlVpFMeta?.name ?? "admin-accounts-account_id-sms_maskings",
    path: indexm0szVIlVpFMeta?.path ?? "",
    meta: indexm0szVIlVpFMeta || {},
    alias: indexm0szVIlVpFMeta?.alias || [],
    redirect: indexm0szVIlVpFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newOUPuwvVrUjMeta?.name ?? "admin-accounts-account_id-sms_maskings-new",
    path: newOUPuwvVrUjMeta?.path ?? "new",
    meta: newOUPuwvVrUjMeta || {},
    alias: newOUPuwvVrUjMeta?.alias || [],
    redirect: newOUPuwvVrUjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingsePHaxjtKN4Meta?.name ?? undefined,
    meta: sms_maskingsePHaxjtKN4Meta || {},
    alias: sms_maskingsePHaxjtKN4Meta?.alias || [],
    redirect: sms_maskingsePHaxjtKN4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id]/sms_maskings.vue").then(m => m.default || m)
  }
],
    name: _91account_id_93iTdeq8kmsPMeta?.name ?? undefined,
    meta: _91account_id_93iTdeq8kmsPMeta || {},
    alias: _91account_id_93iTdeq8kmsPMeta?.alias || [],
    redirect: _91account_id_93iTdeq8kmsPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/[account_id].vue").then(m => m.default || m)
  },
  {
    name: index0fkm3CKHVpMeta?.name ?? "admin-accounts",
    path: index0fkm3CKHVpMeta?.path ?? "",
    meta: index0fkm3CKHVpMeta || {},
    alias: index0fkm3CKHVpMeta?.alias || [],
    redirect: index0fkm3CKHVpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: wizard_plan_selectionFDMXPZ6iSGMeta?.name ?? "admin-accounts-wizard_plan_selection",
    path: wizard_plan_selectionFDMXPZ6iSGMeta?.path ?? "wizard_plan_selection",
    meta: wizard_plan_selectionFDMXPZ6iSGMeta || {},
    alias: wizard_plan_selectionFDMXPZ6iSGMeta?.alias || [],
    redirect: wizard_plan_selectionFDMXPZ6iSGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/wizard_plan_selection.vue").then(m => m.default || m)
  },
  {
    name: wizardW6EBaa6726Meta?.name ?? "admin-accounts-wizard",
    path: wizardW6EBaa6726Meta?.path ?? "wizard",
    meta: wizardW6EBaa6726Meta || {},
    alias: wizardW6EBaa6726Meta?.alias || [],
    redirect: wizardW6EBaa6726Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts/wizard.vue").then(m => m.default || m)
  }
],
    name: accountsmMCcpdELLFMeta?.name ?? undefined,
    meta: accountsmMCcpdELLFMeta || {},
    alias: accountsmMCcpdELLFMeta?.alias || [],
    redirect: accountsmMCcpdELLFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/accounts.vue").then(m => m.default || m)
  },
  {
    path: admin_userstt92EZpw6KMeta?.path ?? "admin_users",
    children: [
  {
    path: _91admin_user_id_93pnaUawiWl2Meta?.path ?? ":admin_user_id()",
    children: [
  {
    name: editTrilwXlIldMeta?.name ?? "admin-admin_users-admin_user_id-edit",
    path: editTrilwXlIldMeta?.path ?? "edit",
    meta: editTrilwXlIldMeta || {},
    alias: editTrilwXlIldMeta?.alias || [],
    redirect: editTrilwXlIldMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/admin_users/[admin_user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexzHP38TsHzDMeta?.name ?? "admin-admin_users-admin_user_id",
    path: indexzHP38TsHzDMeta?.path ?? "",
    meta: indexzHP38TsHzDMeta || {},
    alias: indexzHP38TsHzDMeta?.alias || [],
    redirect: indexzHP38TsHzDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/admin_users/[admin_user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91admin_user_id_93pnaUawiWl2Meta?.name ?? undefined,
    meta: _91admin_user_id_93pnaUawiWl2Meta || {},
    alias: _91admin_user_id_93pnaUawiWl2Meta?.alias || [],
    redirect: _91admin_user_id_93pnaUawiWl2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/admin_users/[admin_user_id].vue").then(m => m.default || m)
  },
  {
    name: indexTyPz8l4HFOMeta?.name ?? "admin-admin_users",
    path: indexTyPz8l4HFOMeta?.path ?? "",
    meta: indexTyPz8l4HFOMeta || {},
    alias: indexTyPz8l4HFOMeta?.alias || [],
    redirect: indexTyPz8l4HFOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/admin_users/index.vue").then(m => m.default || m)
  },
  {
    name: new3GBkW2AqpHMeta?.name ?? "admin-admin_users-new",
    path: new3GBkW2AqpHMeta?.path ?? "new",
    meta: new3GBkW2AqpHMeta || {},
    alias: new3GBkW2AqpHMeta?.alias || [],
    redirect: new3GBkW2AqpHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/admin_users/new.vue").then(m => m.default || m)
  }
],
    name: admin_userstt92EZpw6KMeta?.name ?? undefined,
    meta: admin_userstt92EZpw6KMeta || {},
    alias: admin_userstt92EZpw6KMeta?.alias || [],
    redirect: admin_userstt92EZpw6KMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/admin_users.vue").then(m => m.default || m)
  },
  {
    path: audit_trailsCFpMszjttOMeta?.path ?? "audit_trails",
    children: [
  {
    name: indexijQZXXGCokMeta?.name ?? "admin-audit_trails",
    path: indexijQZXXGCokMeta?.path ?? "",
    meta: indexijQZXXGCokMeta || {},
    alias: indexijQZXXGCokMeta?.alias || [],
    redirect: indexijQZXXGCokMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/audit_trails/index.vue").then(m => m.default || m)
  }
],
    name: audit_trailsCFpMszjttOMeta?.name ?? undefined,
    meta: audit_trailsCFpMszjttOMeta || {},
    alias: audit_trailsCFpMszjttOMeta?.alias || [],
    redirect: audit_trailsCFpMszjttOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/audit_trails.vue").then(m => m.default || m)
  },
  {
    path: billingsnZjAfmM56AMeta?.path ?? "billings",
    children: [
  {
    path: _91billing_id_93TPQYjO4hv5Meta?.path ?? ":billing_id()",
    children: [
  {
    name: edit01Z8EUi4heMeta?.name ?? "admin-billings-billing_id-edit",
    path: edit01Z8EUi4heMeta?.path ?? "edit",
    meta: edit01Z8EUi4heMeta || {},
    alias: edit01Z8EUi4heMeta?.alias || [],
    redirect: edit01Z8EUi4heMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/billings/[billing_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexAYreFot7IaMeta?.name ?? "admin-billings-billing_id",
    path: indexAYreFot7IaMeta?.path ?? "",
    meta: indexAYreFot7IaMeta || {},
    alias: indexAYreFot7IaMeta?.alias || [],
    redirect: indexAYreFot7IaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/billings/[billing_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91billing_id_93TPQYjO4hv5Meta?.name ?? undefined,
    meta: _91billing_id_93TPQYjO4hv5Meta || {},
    alias: _91billing_id_93TPQYjO4hv5Meta?.alias || [],
    redirect: _91billing_id_93TPQYjO4hv5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/billings/[billing_id].vue").then(m => m.default || m)
  },
  {
    name: index64aKbdVnWfMeta?.name ?? "admin-billings",
    path: index64aKbdVnWfMeta?.path ?? "",
    meta: index64aKbdVnWfMeta || {},
    alias: index64aKbdVnWfMeta?.alias || [],
    redirect: index64aKbdVnWfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/billings/index.vue").then(m => m.default || m)
  },
  {
    name: newxWRczke4mLMeta?.name ?? "admin-billings-new",
    path: newxWRczke4mLMeta?.path ?? "new",
    meta: newxWRczke4mLMeta || {},
    alias: newxWRczke4mLMeta?.alias || [],
    redirect: newxWRczke4mLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/billings/new.vue").then(m => m.default || m)
  }
],
    name: billingsnZjAfmM56AMeta?.name ?? undefined,
    meta: billingsnZjAfmM56AMeta || {},
    alias: billingsnZjAfmM56AMeta?.alias || [],
    redirect: billingsnZjAfmM56AMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/billings.vue").then(m => m.default || m)
  },
  {
    path: caller_ids6TrLWNXncMMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_93XlmYGCstB5Meta?.path ?? ":caller_id_id()",
    children: [
  {
    name: edithTHbpT76MOMeta?.name ?? "admin-caller_ids-caller_id_id-edit",
    path: edithTHbpT76MOMeta?.path ?? "edit",
    meta: edithTHbpT76MOMeta || {},
    alias: edithTHbpT76MOMeta?.alias || [],
    redirect: edithTHbpT76MOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexla5qcqV0hpMeta?.name ?? "admin-caller_ids-caller_id_id",
    path: indexla5qcqV0hpMeta?.path ?? "",
    meta: indexla5qcqV0hpMeta || {},
    alias: indexla5qcqV0hpMeta?.alias || [],
    redirect: indexla5qcqV0hpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_93XlmYGCstB5Meta?.name ?? undefined,
    meta: _91caller_id_id_93XlmYGCstB5Meta || {},
    alias: _91caller_id_id_93XlmYGCstB5Meta?.alias || [],
    redirect: _91caller_id_id_93XlmYGCstB5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexOdpCKnDdzNMeta?.name ?? "admin-caller_ids",
    path: indexOdpCKnDdzNMeta?.path ?? "",
    meta: indexOdpCKnDdzNMeta || {},
    alias: indexOdpCKnDdzNMeta?.alias || [],
    redirect: indexOdpCKnDdzNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newBrpTKmnZVmMeta?.name ?? "admin-caller_ids-new",
    path: newBrpTKmnZVmMeta?.path ?? "new",
    meta: newBrpTKmnZVmMeta || {},
    alias: newBrpTKmnZVmMeta?.alias || [],
    redirect: newBrpTKmnZVmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_ids6TrLWNXncMMeta?.name ?? undefined,
    meta: caller_ids6TrLWNXncMMeta || {},
    alias: caller_ids6TrLWNXncMMeta?.alias || [],
    redirect: caller_ids6TrLWNXncMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/caller_ids.vue").then(m => m.default || m)
  },
  {
    path: _91campaign_setting_id_93IVL1Am38MfMeta?.path ?? "campaign_settings/:campaign_setting_id()",
    children: [
  {
    name: edityOKqgJd5JKMeta?.name ?? "admin-campaign_settings-campaign_setting_id-edit",
    path: edityOKqgJd5JKMeta?.path ?? "edit",
    meta: edityOKqgJd5JKMeta || {},
    alias: edityOKqgJd5JKMeta?.alias || [],
    redirect: edityOKqgJd5JKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexG6brhHhAlOMeta?.name ?? "admin-campaign_settings-campaign_setting_id",
    path: indexG6brhHhAlOMeta?.path ?? "",
    meta: indexG6brhHhAlOMeta || {},
    alias: indexG6brhHhAlOMeta?.alias || [],
    redirect: indexG6brhHhAlOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91campaign_setting_id_93IVL1Am38MfMeta?.name ?? undefined,
    meta: _91campaign_setting_id_93IVL1Am38MfMeta || {},
    alias: _91campaign_setting_id_93IVL1Am38MfMeta?.alias || [],
    redirect: _91campaign_setting_id_93IVL1Am38MfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaign_settings/[campaign_setting_id].vue").then(m => m.default || m)
  },
  {
    path: campaignsVRUcuUV64sMeta?.path ?? "campaigns",
    children: [
  {
    path: _91campaign_id_93h0TbmorRyBMeta?.path ?? ":campaign_id()",
    children: [
  {
    name: editZrwtEdd4GDMeta?.name ?? "admin-campaigns-campaign_id-edit",
    path: editZrwtEdd4GDMeta?.path ?? "edit",
    meta: editZrwtEdd4GDMeta || {},
    alias: editZrwtEdd4GDMeta?.alias || [],
    redirect: editZrwtEdd4GDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index70K6Qbr52hMeta?.name ?? "admin-campaigns-campaign_id",
    path: index70K6Qbr52hMeta?.path ?? "",
    meta: index70K6Qbr52hMeta || {},
    alias: index70K6Qbr52hMeta?.alias || [],
    redirect: index70K6Qbr52hMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/index.vue").then(m => m.default || m)
  },
  {
    path: outbound_call_transactions9zt2s2P8ONMeta?.path ?? "outbound_call_transactions",
    children: [
  {
    path: _91outbound_call_transaction_id_93rhHWj9IzL2Meta?.path ?? ":outbound_call_transaction_id()",
    children: [
  {
    name: editb2hq4pgZizMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id-edit",
    path: editb2hq4pgZizMeta?.path ?? "edit",
    meta: editb2hq4pgZizMeta || {},
    alias: editb2hq4pgZizMeta?.alias || [],
    redirect: editb2hq4pgZizMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexEVbMofXobDMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id",
    path: indexEVbMofXobDMeta?.path ?? "",
    meta: indexEVbMofXobDMeta || {},
    alias: indexEVbMofXobDMeta?.alias || [],
    redirect: indexEVbMofXobDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91outbound_call_transaction_id_93rhHWj9IzL2Meta?.name ?? undefined,
    meta: _91outbound_call_transaction_id_93rhHWj9IzL2Meta || {},
    alias: _91outbound_call_transaction_id_93rhHWj9IzL2Meta?.alias || [],
    redirect: _91outbound_call_transaction_id_93rhHWj9IzL2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue").then(m => m.default || m)
  },
  {
    name: indexBe11ksMGcvMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions",
    path: indexBe11ksMGcvMeta?.path ?? "",
    meta: indexBe11ksMGcvMeta || {},
    alias: indexBe11ksMGcvMeta?.alias || [],
    redirect: indexBe11ksMGcvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue").then(m => m.default || m)
  },
  {
    name: newSzb32VpQlNMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-new",
    path: newSzb32VpQlNMeta?.path ?? "new",
    meta: newSzb32VpQlNMeta || {},
    alias: newSzb32VpQlNMeta?.alias || [],
    redirect: newSzb32VpQlNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue").then(m => m.default || m)
  }
],
    name: outbound_call_transactions9zt2s2P8ONMeta?.name ?? undefined,
    meta: outbound_call_transactions9zt2s2P8ONMeta || {},
    alias: outbound_call_transactions9zt2s2P8ONMeta?.alias || [],
    redirect: outbound_call_transactions9zt2s2P8ONMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue").then(m => m.default || m)
  }
],
    name: _91campaign_id_93h0TbmorRyBMeta?.name ?? undefined,
    meta: _91campaign_id_93h0TbmorRyBMeta || {},
    alias: _91campaign_id_93h0TbmorRyBMeta?.alias || [],
    redirect: _91campaign_id_93h0TbmorRyBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaigns/[campaign_id].vue").then(m => m.default || m)
  },
  {
    name: indexRma0NuSLtXMeta?.name ?? "admin-campaigns",
    path: indexRma0NuSLtXMeta?.path ?? "",
    meta: indexRma0NuSLtXMeta || {},
    alias: indexRma0NuSLtXMeta?.alias || [],
    redirect: indexRma0NuSLtXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: newiK8DwTyuAVMeta?.name ?? "admin-campaigns-new",
    path: newiK8DwTyuAVMeta?.path ?? "new",
    meta: newiK8DwTyuAVMeta || {},
    alias: newiK8DwTyuAVMeta?.alias || [],
    redirect: newiK8DwTyuAVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaigns/new.vue").then(m => m.default || m)
  }
],
    name: campaignsVRUcuUV64sMeta?.name ?? undefined,
    meta: campaignsVRUcuUV64sMeta || {},
    alias: campaignsVRUcuUV64sMeta?.alias || [],
    redirect: campaignsVRUcuUV64sMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/campaigns.vue").then(m => m.default || m)
  },
  {
    path: custom_emailsRRjPs1uuBrMeta?.path ?? "custom_emails",
    children: [
  {
    path: _91custom_email_id_93oJwyUItSMDMeta?.path ?? ":custom_email_id()",
    children: [
  {
    name: indexkVkxjwxWM7Meta?.name ?? "admin-custom_emails-custom_email_id",
    path: indexkVkxjwxWM7Meta?.path ?? "",
    meta: indexkVkxjwxWM7Meta || {},
    alias: indexkVkxjwxWM7Meta?.alias || [],
    redirect: indexkVkxjwxWM7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/custom_emails/[custom_email_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91custom_email_id_93oJwyUItSMDMeta?.name ?? undefined,
    meta: _91custom_email_id_93oJwyUItSMDMeta || {},
    alias: _91custom_email_id_93oJwyUItSMDMeta?.alias || [],
    redirect: _91custom_email_id_93oJwyUItSMDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/custom_emails/[custom_email_id].vue").then(m => m.default || m)
  },
  {
    name: indexStDvJBuxAGMeta?.name ?? "admin-custom_emails",
    path: indexStDvJBuxAGMeta?.path ?? "",
    meta: indexStDvJBuxAGMeta || {},
    alias: indexStDvJBuxAGMeta?.alias || [],
    redirect: indexStDvJBuxAGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/custom_emails/index.vue").then(m => m.default || m)
  },
  {
    name: newHfolatDFNlMeta?.name ?? "admin-custom_emails-new",
    path: newHfolatDFNlMeta?.path ?? "new",
    meta: newHfolatDFNlMeta || {},
    alias: newHfolatDFNlMeta?.alias || [],
    redirect: newHfolatDFNlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/custom_emails/new.vue").then(m => m.default || m)
  }
],
    name: custom_emailsRRjPs1uuBrMeta?.name ?? undefined,
    meta: custom_emailsRRjPs1uuBrMeta || {},
    alias: custom_emailsRRjPs1uuBrMeta?.alias || [],
    redirect: custom_emailsRRjPs1uuBrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/custom_emails.vue").then(m => m.default || m)
  },
  {
    path: dashboard4NSdhzYpsmMeta?.path ?? "dashboard",
    children: [
  {
    name: indexuwEC4w4dV5Meta?.name ?? "admin-dashboard",
    path: indexuwEC4w4dV5Meta?.path ?? "",
    meta: indexuwEC4w4dV5Meta || {},
    alias: indexuwEC4w4dV5Meta?.alias || [],
    redirect: indexuwEC4w4dV5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/dashboard/index.vue").then(m => m.default || m)
  }
],
    name: dashboard4NSdhzYpsmMeta?.name ?? undefined,
    meta: dashboard4NSdhzYpsmMeta || {},
    alias: dashboard4NSdhzYpsmMeta?.alias || [],
    redirect: dashboard4NSdhzYpsmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    path: inquiriesid3DABxRlgMeta?.path ?? "inquiries",
    children: [
  {
    path: _91inquiry_id_93JBM0zRglleMeta?.path ?? ":inquiry_id()",
    children: [
  {
    name: indexliWPATasyaMeta?.name ?? "admin-inquiries-inquiry_id",
    path: indexliWPATasyaMeta?.path ?? "",
    meta: indexliWPATasyaMeta || {},
    alias: indexliWPATasyaMeta?.alias || [],
    redirect: indexliWPATasyaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/inquiries/[inquiry_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91inquiry_id_93JBM0zRglleMeta?.name ?? undefined,
    meta: _91inquiry_id_93JBM0zRglleMeta || {},
    alias: _91inquiry_id_93JBM0zRglleMeta?.alias || [],
    redirect: _91inquiry_id_93JBM0zRglleMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/inquiries/[inquiry_id].vue").then(m => m.default || m)
  },
  {
    name: indexGSgp1bmeS8Meta?.name ?? "admin-inquiries",
    path: indexGSgp1bmeS8Meta?.path ?? "",
    meta: indexGSgp1bmeS8Meta || {},
    alias: indexGSgp1bmeS8Meta?.alias || [],
    redirect: indexGSgp1bmeS8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/inquiries/index.vue").then(m => m.default || m)
  },
  {
    name: new24ct2pB0STMeta?.name ?? "admin-inquiries-new",
    path: new24ct2pB0STMeta?.path ?? "new",
    meta: new24ct2pB0STMeta || {},
    alias: new24ct2pB0STMeta?.alias || [],
    redirect: new24ct2pB0STMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/inquiries/new.vue").then(m => m.default || m)
  }
],
    name: inquiriesid3DABxRlgMeta?.name ?? undefined,
    meta: inquiriesid3DABxRlgMeta || {},
    alias: inquiriesid3DABxRlgMeta?.alias || [],
    redirect: inquiriesid3DABxRlgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/inquiries.vue").then(m => m.default || m)
  },
  {
    path: loginHMXcqaDb8zMeta?.path ?? "login",
    children: [
  {
    name: indexZF5yMykjT9Meta?.name ?? "admin-login",
    path: indexZF5yMykjT9Meta?.path ?? "",
    meta: indexZF5yMykjT9Meta || {},
    alias: indexZF5yMykjT9Meta?.alias || [],
    redirect: indexZF5yMykjT9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/login/index.vue").then(m => m.default || m)
  }
],
    name: loginHMXcqaDb8zMeta?.name ?? undefined,
    meta: loginHMXcqaDb8zMeta || {},
    alias: loginHMXcqaDb8zMeta?.alias || [],
    redirect: loginHMXcqaDb8zMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: newL3xvsrItCrMeta?.name ?? "admin-password-new",
    path: newL3xvsrItCrMeta?.path ?? "password/new",
    meta: newL3xvsrItCrMeta || {},
    alias: newL3xvsrItCrMeta?.alias || [],
    redirect: newL3xvsrItCrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/password/new.vue").then(m => m.default || m)
  },
  {
    path: reportsjgFba8EPFnMeta?.path ?? "reports",
    children: [
  {
    name: indexj9lBr8ZZTbMeta?.name ?? "admin-reports",
    path: indexj9lBr8ZZTbMeta?.path ?? "",
    meta: indexj9lBr8ZZTbMeta || {},
    alias: indexj9lBr8ZZTbMeta?.alias || [],
    redirect: indexj9lBr8ZZTbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/reports/index.vue").then(m => m.default || m)
  }
],
    name: reportsjgFba8EPFnMeta?.name ?? undefined,
    meta: reportsjgFba8EPFnMeta || {},
    alias: reportsjgFba8EPFnMeta?.alias || [],
    redirect: reportsjgFba8EPFnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingsRiIoQCh5ROMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93Zo0uMMdvqZMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: edit3SZGpc10zXMeta?.name ?? "admin-sms_maskings-sms_masking_id-edit",
    path: edit3SZGpc10zXMeta?.path ?? "edit",
    meta: edit3SZGpc10zXMeta || {},
    alias: edit3SZGpc10zXMeta?.alias || [],
    redirect: edit3SZGpc10zXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexIORCTKtGQyMeta?.name ?? "admin-sms_maskings-sms_masking_id",
    path: indexIORCTKtGQyMeta?.path ?? "",
    meta: indexIORCTKtGQyMeta || {},
    alias: indexIORCTKtGQyMeta?.alias || [],
    redirect: indexIORCTKtGQyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93Zo0uMMdvqZMeta?.name ?? undefined,
    meta: _91sms_masking_id_93Zo0uMMdvqZMeta || {},
    alias: _91sms_masking_id_93Zo0uMMdvqZMeta?.alias || [],
    redirect: _91sms_masking_id_93Zo0uMMdvqZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: index5eMn98zc43Meta?.name ?? "admin-sms_maskings",
    path: index5eMn98zc43Meta?.path ?? "",
    meta: index5eMn98zc43Meta || {},
    alias: index5eMn98zc43Meta?.alias || [],
    redirect: index5eMn98zc43Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newacdkRpPL9CMeta?.name ?? "admin-sms_maskings-new",
    path: newacdkRpPL9CMeta?.path ?? "new",
    meta: newacdkRpPL9CMeta || {},
    alias: newacdkRpPL9CMeta?.alias || [],
    redirect: newacdkRpPL9CMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingsRiIoQCh5ROMeta?.name ?? undefined,
    meta: sms_maskingsRiIoQCh5ROMeta || {},
    alias: sms_maskingsRiIoQCh5ROMeta?.alias || [],
    redirect: sms_maskingsRiIoQCh5ROMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/sms_maskings.vue").then(m => m.default || m)
  },
  {
    path: users6KdAXy1AoHMeta?.path ?? "users",
    children: [
  {
    path: _91user_id_93ZelpQh0JTaMeta?.path ?? ":user_id()",
    children: [
  {
    name: editvQvAprB685Meta?.name ?? "admin-users-user_id-edit",
    path: editvQvAprB685Meta?.path ?? "edit",
    meta: editvQvAprB685Meta || {},
    alias: editvQvAprB685Meta?.alias || [],
    redirect: editvQvAprB685Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexn4lhh0NuiZMeta?.name ?? "admin-users-user_id",
    path: indexn4lhh0NuiZMeta?.path ?? "",
    meta: indexn4lhh0NuiZMeta || {},
    alias: indexn4lhh0NuiZMeta?.alias || [],
    redirect: indexn4lhh0NuiZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/users/[user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91user_id_93ZelpQh0JTaMeta?.name ?? undefined,
    meta: _91user_id_93ZelpQh0JTaMeta || {},
    alias: _91user_id_93ZelpQh0JTaMeta?.alias || [],
    redirect: _91user_id_93ZelpQh0JTaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/users/[user_id].vue").then(m => m.default || m)
  },
  {
    name: indexnNGVmkZOeBMeta?.name ?? "admin-users",
    path: indexnNGVmkZOeBMeta?.path ?? "",
    meta: indexnNGVmkZOeBMeta || {},
    alias: indexnNGVmkZOeBMeta?.alias || [],
    redirect: indexnNGVmkZOeBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: newp0iFl67aimMeta?.name ?? "admin-users-new",
    path: newp0iFl67aimMeta?.path ?? "new",
    meta: newp0iFl67aimMeta || {},
    alias: newp0iFl67aimMeta?.alias || [],
    redirect: newp0iFl67aimMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/users/new.vue").then(m => m.default || m)
  }
],
    name: users6KdAXy1AoHMeta?.name ?? undefined,
    meta: users6KdAXy1AoHMeta || {},
    alias: users6KdAXy1AoHMeta?.alias || [],
    redirect: users6KdAXy1AoHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    path: _91wallet_id_93wLK2mxdZSTMeta?.path ?? "wallets/:wallet_id()",
    children: [
  {
    name: editnnZgKaBih2Meta?.name ?? "admin-wallets-wallet_id-edit",
    path: editnnZgKaBih2Meta?.path ?? "edit",
    meta: editnnZgKaBih2Meta || {},
    alias: editnnZgKaBih2Meta?.alias || [],
    redirect: editnnZgKaBih2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/wallets/[wallet_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexBrFmW6S3mKMeta?.name ?? "admin-wallets-wallet_id",
    path: indexBrFmW6S3mKMeta?.path ?? "",
    meta: indexBrFmW6S3mKMeta || {},
    alias: indexBrFmW6S3mKMeta?.alias || [],
    redirect: indexBrFmW6S3mKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/wallets/[wallet_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91wallet_id_93wLK2mxdZSTMeta?.name ?? undefined,
    meta: _91wallet_id_93wLK2mxdZSTMeta || {},
    alias: _91wallet_id_93wLK2mxdZSTMeta?.alias || [],
    redirect: _91wallet_id_93wLK2mxdZSTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin/wallets/[wallet_id].vue").then(m => m.default || m)
  }
],
    meta: adminfP47rE7RqOMeta || {},
    alias: adminfP47rE7RqOMeta?.alias || [],
    redirect: adminfP47rE7RqOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: index6s1evIXF7sMeta?.name ?? "audit_logs",
    path: index6s1evIXF7sMeta?.path ?? "/audit_logs",
    meta: index6s1evIXF7sMeta || {},
    alias: index6s1evIXF7sMeta?.alias || [],
    redirect: index6s1evIXF7sMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/audit_logs/index.vue").then(m => m.default || m)
  },
  {
    name: indexto88jtIEgzMeta?.name ?? "billings",
    path: indexto88jtIEgzMeta?.path ?? "/billings",
    meta: indexto88jtIEgzMeta || {},
    alias: indexto88jtIEgzMeta?.alias || [],
    redirect: indexto88jtIEgzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/billings/index.vue").then(m => m.default || m)
  },
  {
    name: indexjlXl3Deee0Meta?.name ?? "call_out-reports",
    path: indexjlXl3Deee0Meta?.path ?? "/call_out/reports",
    meta: indexjlXl3Deee0Meta || {},
    alias: indexjlXl3Deee0Meta?.alias || [],
    redirect: indexjlXl3Deee0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/call_out/reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lCt3XpAd1IMeta?.name ?? "call_recordings-id",
    path: _91id_93lCt3XpAd1IMeta?.path ?? "/call_recordings/:id()",
    meta: _91id_93lCt3XpAd1IMeta || {},
    alias: _91id_93lCt3XpAd1IMeta?.alias || [],
    redirect: _91id_93lCt3XpAd1IMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/call_recordings/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhUuatcgtUHMeta?.name ?? "campaigns-id",
    path: indexhUuatcgtUHMeta?.path ?? "/campaigns/:id()",
    meta: indexhUuatcgtUHMeta || {},
    alias: indexhUuatcgtUHMeta?.alias || [],
    redirect: indexhUuatcgtUHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/campaigns/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAt8Gb4tLJrMeta?.name ?? "campaigns",
    path: indexAt8Gb4tLJrMeta?.path ?? "/campaigns",
    meta: indexAt8Gb4tLJrMeta || {},
    alias: indexAt8Gb4tLJrMeta?.alias || [],
    redirect: indexAt8Gb4tLJrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexbNtYr5ZZQCMeta?.name ?? "dashboard",
    path: indexbNtYr5ZZQCMeta?.path ?? "/dashboard",
    meta: indexbNtYr5ZZQCMeta || {},
    alias: indexbNtYr5ZZQCMeta?.alias || [],
    redirect: indexbNtYr5ZZQCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexo5R5uE4KJJMeta?.name ?? "help",
    path: indexo5R5uE4KJJMeta?.path ?? "/help",
    meta: indexo5R5uE4KJJMeta || {},
    alias: indexo5R5uE4KJJMeta?.alias || [],
    redirect: indexo5R5uE4KJJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexmiTWJIGrUtMeta?.name ?? "index",
    path: indexmiTWJIGrUtMeta?.path ?? "/",
    meta: indexmiTWJIGrUtMeta || {},
    alias: indexmiTWJIGrUtMeta?.alias || [],
    redirect: indexmiTWJIGrUtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index8FayRMQPRZMeta?.name ?? "inquiry",
    path: index8FayRMQPRZMeta?.path ?? "/inquiry",
    meta: index8FayRMQPRZMeta || {},
    alias: index8FayRMQPRZMeta?.alias || [],
    redirect: index8FayRMQPRZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: editorKVpcp4OQzzMeta?.name ?? "ivr_trees-id-editor",
    path: editorKVpcp4OQzzMeta?.path ?? "/ivr_trees/:id()/editor",
    meta: editorKVpcp4OQzzMeta || {},
    alias: editorKVpcp4OQzzMeta?.alias || [],
    redirect: editorKVpcp4OQzzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/ivr_trees/[id]/editor.vue").then(m => m.default || m)
  },
  {
    name: indexyGb91IC3xMMeta?.name ?? "ivr_trees",
    path: indexyGb91IC3xMMeta?.path ?? "/ivr_trees",
    meta: indexyGb91IC3xMMeta || {},
    alias: indexyGb91IC3xMMeta?.alias || [],
    redirect: indexyGb91IC3xMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/ivr_trees/index.vue").then(m => m.default || m)
  },
  {
    name: verify_emailcQ9ooqLtffMeta?.name ?? "profiles-user_id-verify_email",
    path: verify_emailcQ9ooqLtffMeta?.path ?? "/profiles/:user_id()/verify_email",
    meta: verify_emailcQ9ooqLtffMeta || {},
    alias: verify_emailcQ9ooqLtffMeta?.alias || [],
    redirect: verify_emailcQ9ooqLtffMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/profiles/[user_id]/verify_email.vue").then(m => m.default || m)
  },
  {
    name: welcome_dashboardtKyfUfRYeaMeta?.name ?? "profiles-user_id-welcome_dashboard",
    path: welcome_dashboardtKyfUfRYeaMeta?.path ?? "/profiles/:user_id()/welcome_dashboard",
    meta: welcome_dashboardtKyfUfRYeaMeta || {},
    alias: welcome_dashboardtKyfUfRYeaMeta?.alias || [],
    redirect: welcome_dashboardtKyfUfRYeaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/profiles/[user_id]/welcome_dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexQtcYFUcAPGMeta?.name ?? "profiles",
    path: indexQtcYFUcAPGMeta?.path ?? "/profiles",
    meta: indexQtcYFUcAPGMeta || {},
    alias: indexQtcYFUcAPGMeta?.alias || [],
    redirect: indexQtcYFUcAPGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: indexiW3qyS6xj1Meta?.name ?? "sub_users-users",
    path: indexiW3qyS6xj1Meta?.path ?? "/sub_users/users",
    meta: indexiW3qyS6xj1Meta || {},
    alias: indexiW3qyS6xj1Meta?.alias || [],
    redirect: indexiW3qyS6xj1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/sub_users/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexCsEmIWPQOXMeta?.name ?? "terms_and_conditions",
    path: indexCsEmIWPQOXMeta?.path ?? "/terms_and_conditions",
    meta: indexCsEmIWPQOXMeta || {},
    alias: indexCsEmIWPQOXMeta?.alias || [],
    redirect: indexCsEmIWPQOXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/terms_and_conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexQur1sWVi9LMeta?.name ?? "users-password-edit",
    path: indexQur1sWVi9LMeta?.path ?? "/users/password/edit",
    meta: indexQur1sWVi9LMeta || {},
    alias: indexQur1sWVi9LMeta?.alias || [],
    redirect: indexQur1sWVi9LMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/users/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: index9SJkdbIvl9Meta?.name ?? "users-password-new",
    path: index9SJkdbIvl9Meta?.path ?? "/users/password/new",
    meta: index9SJkdbIvl9Meta || {},
    alias: index9SJkdbIvl9Meta?.alias || [],
    redirect: index9SJkdbIvl9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/users/password/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexC7Lb2l9U4hMeta?.name ?? "users-sign_in",
    path: indexC7Lb2l9U4hMeta?.path ?? "/users/sign_in",
    meta: indexC7Lb2l9U4hMeta || {},
    alias: indexC7Lb2l9U4hMeta?.alias || [],
    redirect: indexC7Lb2l9U4hMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1361907415/src/pages/users/sign_in/index.vue").then(m => m.default || m)
  }
]