import { z } from "zod"

export const Request = {
        SignIn: z.object({
                user: z.object({
                        otp_secret_key: z.string(),
                        otp_code: z.string(),
                        g_recaptcha_response: z.string(),
                        email: z.string(),
                }),
        }).safeParse,
        PreLogin: z.object({
                email: z.string(),
                password: z.string(),
                g_recaptcha_response: z.string(),
        }).safeParse,
        ResendOtp: z.object({
                email: z.string(),
                otp_secret_key: z.string(),
        }).safeParse,
        ForgotPassword: z.object({
                user: z.object({
                        email: z.string(),
                }),
        }).safeParse,
        SetPassword: z.object({
                user: z.object({
                        reset_password_token: z.string(),
                        password: z.string(),
                        password_confirmation: z.string(),
                }),
        }).safeParse,
}

export const Response = {
        SignIn: z.object({
                id: z.number(),
                account_id: z.number(),
                email: z.string(),
                mobile: z.string(),
                first_name: z.string(),
                last_name: z.string(),
                role_id: z.number(),
                status: z.number(),
                user_is_dms_enabled: z.boolean(),
                access_rights: z.object({
                        module: z.string(),
                        permission: z.string(),
                }).array(),
                role_name: z.enum([
                        "admin",
                        "client",
                        "sub user",
                ]),
        }).safeParse,
        PreLogin: z.object({
                email: z.string(),
                otp_secret_key: z.string(),
        }).safeParse,
        ValidateOtp: z.object({
                email: z.string(),
                otp_secret_key: z.string(),
        }).safeParse,
        AdminSignIn: z.object({
                id: z.number(),
                current_sign_in_token: z.string(), // NOTE(aes): "U8iufg-bgsknpk4AoJFM"
                email: z.string(),
                first_name: z.string(),
                last_name: z.string(),
                created_at: z.string(), // NOTE(aes): "2024-02-14T11:13:29.544+08:00",
                updated_at: z.string(), // NOTE(aes): "2024-02-22T11:24:58.694+08:00"
        }).safeParse,
}

export const Error = {
        SignIn: z.object({
                error: z.string(),
        }).safeParse,
        PreLogin: z.object({
                error: z.string(),
        }).safeParse,
        ValidateOtp: z.object({
                error: z.string(),
        }).safeParse,
}
